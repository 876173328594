import axios from "../interceptor";
import { headersConfig } from "../../constants/constants";

const UserEndpoints = {
  userDetails: "farmer_scorecard_api",
  userDetailsPaddy2022: "farmer_scorecard_api/paddy_2022",
};

const UserAPI = {
  userDetails: function (data, route) {
    let url = `/${UserEndpoints.userDetails}/${data}/`;
    if (route == "paddy_2022") {
      url = `/${UserEndpoints.userDetailsPaddy2022}/${data}/`;
    }
    return axios.get(url, headersConfig);
  },
};

export default UserAPI;
