import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    container: {
        backgroundColor: '#FFFFFF',
        marginTop: '0px',
        padding: '0',
    },
    userCard: {
        backgroundColor: '#F0F0F0',
    },
    headerContainer: {
        backgroundColor: '#fff',
        marginBottom: '5px',
    },
    iconColor: {
        color: 'black',
        transform: 'rotate(-90deg)'
    },
    detailsWrapper: {
        // paddingTop: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
        marginTop: '0px',
        // border : "1px solid black"
    },
    cardTitle: {
        fontSize: '16px',
        textAlign: 'center',
        marginBottom: '5px',
        fontWeight: '400',
        color: '#656767',
    },
    cropDetails: {
        backgroundColor: '#FFFFFF',
        padding: '35px 32px',
        borderRadius: '15px 15px 0 0',
    },
    
   
}))
