import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    headerContainer: {
        backgroundColor: '#fff',
        marginBottom: '5px',
    },
    iconColor: {
        color: 'black',
        transform: 'rotate(-90deg)'
    },
    detailsWrapper: {
        // paddingTop: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
        border: '1px solid black',
        marginTop: '30px',
        // border : "1px solid black"
    },
   
}))
