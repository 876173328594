import React from "react";
import "./App.css";
import { StyledEngineProvider } from "@mui/material";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/Header/Header";
import PaddyReport from "./pages/paddyReport/PaddyReport.js";
import WheatReport from "./pages/wheatReport/WheatReport";
import PaddyReportNewDesign from "./pages/paddyReportNewDesign/PaddyReport.js";
import WheatReportNewDesign from "./pages/wheatReportNewDesign/WheatReport";
// import PaddyReportEN from './pages/reportCardEN/paddyReport/PaddyReport.js';
// import WheatReportEN from './pages/wheatReport/WheatReport';
// import PaddyReportNewDesignEN from './pages/paddyReportNewDesign/PaddyReport.js';
// import WheatReportNewDesignEN from './pages/wheatReportNewDesign/WheatReport';

function App() {
  return (
    <>
      <StyledEngineProvider injectFirst>
        <Header />
        <Router>
          <Routes>
            <Route
              path="/paddy_2021"
              element={<Navigate to="/paddy_2021/hi" />}
            />
            <Route
              path="/paddy_2021/new_design"
              element={<Navigate to="/paddy_2021/new_design/hi" />}
            />
            <Route
              path="/wheat_2021"
              element={<Navigate to="/wheat_2021/hi" />}
            />
            <Route
              path="/wheat_2021/new_design"
              element={<Navigate to="/wheat_2021/new_design/hi" />}
            />
            <Route path="/" element={<Navigate to="/paddy_2021" />} />

            {/* Multi language components */}
            {/* Routes for English */}
            <Route path="/paddy_2021/en/*" element={<PaddyReport />} />
            <Route
              path="/paddy_2022/en/*"
              element={<PaddyReport paddy2022={true} />}
            />
            <Route
              path="/paddy_2021/new_design/en/*"
              element={<PaddyReportNewDesign />}
            />
            <Route
              path="/paddy_2022/new_design/en/*"
              element={<PaddyReportNewDesign paddy2022={true} />}
            />
            <Route path="/wheat_2021/en/*" element={<WheatReport />} />
            <Route path="/wheat_2022/hi/:id" element={<WheatReport />} />
            <Route path="/wheat_2022/en/:id" element={<WheatReport />} />
            <Route
              path="/wheat_2021/new_design/en/*"
              element={<WheatReportNewDesign />}
            />
            <Route
              path="/wheat_2022/new_design/en/*"
              element={<WheatReportNewDesign />}
            />
            <Route path="/en" element={<Navigate to="/paddy_2021/en" />} />

            {/* Routes for Hindi */}
            <Route path="/paddy_2021/hi/*" element={<PaddyReport />} />
            <Route
              path="/paddy_2022/hi/*"
              element={<PaddyReport paddy2022={true} />}
            />
            <Route
              path="/paddy_2021/new_design/hi/*"
              element={<PaddyReportNewDesign />}
            />
            <Route
              path="/paddy_2022/new_design/hi/*"
              element={<PaddyReportNewDesign paddy2022={true} />}
            />
            <Route path="/wheat_2021/hi/*" element={<WheatReport />} />
            <Route
              path="/wheat_2021/new_design/hi/*"
              element={<WheatReportNewDesign />}
            />
            <Route
              path="/wheat_2022/new_design/hi/*"
              element={<WheatReportNewDesign />}
            />
            <Route path="/hi" element={<Navigate to="/paddy_2021/hi" />} />
            <Route
              path="/paddy_2022"
              element={<Navigate to="/paddy_2022/hi" />}
            />
          </Routes>
        </Router>
      </StyledEngineProvider>
    </>
  );
}

export default App;
