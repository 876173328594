import React from "react";
import { Card } from "@mui/material";

import useStyles from "./ManureDetailsStyles";
import FertilizerDetails from "../Fertilizer/FertilizerDetails";

function ManureDetailsContainer(props) {
  const fertilizerDetails = {
    // Data should be like this [urea, D.A.P, firtalizer]
    userUses: props.userManureUses,
    averageUses: props.averageManureUses,
    advisedUses: props.advisedManureUsage,
    userAndAdvisedDiff: props.userAndAdvisedManureDiff,
    active: true,
  };
  const fertilizerType = [
    props.label.manure1,
    props.label.manure2,
    props.label.manure3,
  ];

  const classes = useStyles();

  return (
    <>
      {fertilizerType.map((type, index) => {
        return (
          <FertilizerDetails
            key={index}
            index={index}
            fertilizerType={type}
            userUses={fertilizerDetails.userUses[index]}
            averageUses={fertilizerDetails.averageUses[index]}
            advisedUses={fertilizerDetails.advisedUses[index]}
            userAndAdvisedDiff={fertilizerDetails.userAndAdvisedDiff[index]}
            label={props.label}
            paddy2022={props.paddy2022}
          />
        );
      })}
    </>
  );
}

export default ManureDetailsContainer;
