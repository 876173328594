import React from "react";
import { Card, Divider } from "@mui/material";

import useStyles from "./FertilizerDetailsStyles";

function FertilizerDetails(props) {
  const classes = useStyles();
  let cardClassName = classes.fertilizerTypeCardOne;
  let className = classes.blueColor;
  if (props.index === 1) {
    cardClassName = classes.fertilizerTypeCardTwo;
    className = classes.redColor;
  } else if (props.index === 2) {
    cardClassName = classes.fertilizerTypeCardThree;
    className = classes.greenColor;
  }
  let overUsed =
    props.userAndAdvisedDiff !== 0 &&
    props.userAndAdvisedDiff.startsWith("-") === false &&
    props.userAndAdvisedDiff.startsWith("0 ") === false
      ? classes.overUsed
      : classes.underUsed;

  let regex = /\b0 grams\b/;

  return (
    <>
      {
        <Card elevation={3} className={cardClassName}>
          <p
            className={
              props.index === 2
                ? "text__center m__b__10 black__text"
                : "text__center m__b__10 green__text"
            }
          >
            {props.fertilizerType}
          </p>
          <div className={classes.contentWrapper}>
            <div className={classes.detailsContainer}>
              <p>{props.label.farmerUsesManure}</p>
              <p className={overUsed}>
                {regex.test(props.userUses)
                  ? props.userUses.replace("0 grams", "")
                  : props.userUses}{" "}
                {props.index === 2 && props.paddy2022
                  ? props.label.quintal
                  : props.index === 2
                  ? props.label.tractor
                  : regex.test(props.userAndAdvisedDiff)
                  ? props.userAndAdvisedDiff.replace("0 grams", "")
                  : ` (${props.userAndAdvisedDiff}) `}
              </p>
            </div>
            <Divider
              flexItem
              orientation="vertical"
              className={classes.dividerColor}
            />
            <div className={classes.detailsContainer}>
              <p>{props.label.averageUsesManure}</p>
              <p className={className}>
                {regex.test(props.averageUses)
                  ? props.averageUses.replace("0 grams", "")
                  : props.averageUses}{" "}
                {props.index === 2 && props.paddy2022
                  ? props.label.quintal
                  : props.index === 2
                  ? props.label.tractor
                  : ""}{" "}
              </p>
            </div>
            {props.index === 2 ? null : (
              <>
                <Divider
                  flexItem
                  orientation="vertical"
                  className={classes.dividerColor}
                />
                <div className={classes.detailsContainer}>
                  <p>{props.label.advisedManure}</p>
                  <p className={className}>
                    {regex.test(props.advisedUses)
                      ? props.advisedUses.replace("0 grams", "")
                      : props.advisedUses}{" "}
                    {props.index === 2 && props.paddy2022
                      ? props.label.quintal
                      : props.index === 2
                      ? props.label.tractor
                      : ""}
                  </p>
                </div>
              </>
            )}
          </div>
        </Card>
      }
    </>
  );
}

export default FertilizerDetails;
