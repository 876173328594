import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
    yieldCard: {
        backgroundColor: 'var(--lb-color)',
        padding: '5px',
        marginBottom: '10px'
    },
    areaCard: {
        backgroundColor: 'var(--lg-color)',
        padding: '5px',
        marginBottom: '10px'
    },
    contentWrapper: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    detailsContainer: {
        textAlign: 'center',
        flex: '50%',
        
        '& p:last-child': {
            fontWeight: 'bold',
            fontSize: '15px'
        }
    },
    dividerColor: {
        backgroundColor: 'black'
    },
    yaildColorGreen: {
        color: 'green',
        fontWeight: 'bold'
    },
    yaildColorRed: {
        color: 'red',
        fontWeight: 'bold'
    },
}))