import React from "react";
import { Divider, Card } from "@mui/material";

import useStyles from "./AreaDetailsStyles";

function AreaDetailsContainer(props) {
  const classes = useStyles();

  return (
    <>
      {
        <Card elevation={3} className={classes.areaCard}>
          <p>
            {props.label.fieldTitle}{" "}
            <strong className={classes.areaQty}>{props.yieldArea}</strong>
            
          </p>
        </Card>
      }
      {
        <Card elevation={3} className={classes.yieldCard}>
          <p className="text__center m__b__10 green__text">{props.label.yieldTitle}</p>
          <div className={classes.contentWrapper}>
            <div className={classes.detailsContainer}>
              <p>{props.label.farmerYield}</p>
              <p
                className={
                  props.userYield < props.averageYield
                    ? classes.yaildColorRed
                    : classes.yaildColorGreen
                }
              >
                {props.userYield}
              </p>
            </div>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.dividerColor}
            />
            <div className={classes.detailsContainer}>
              <p>{props.label.wheatAverageYield}</p>
              <p>{props.averageYield}</p>
            </div>
          </div>
        </Card>
      }
    </>
  );
}

export default AreaDetailsContainer;
