import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
    framerWrapper: {
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '80vh'
    },
    userHeading: {
        fontSize: '20px',
        textAlign: 'center',
        marginBottom: '5px'
    },
    numberContainer: {
        padding: '15px',
        marginBottom: '10px',
        marginTop: '30px',
        borderRadius: '10px',
        boxShadow: 'none',
        border: 'none',
    },
    submitBtn: {
        marginTop: '20px',
        height: '50px',
        borderRadius: '8px',
        backgroundColor: 'var(--btn-color)',
        color: 'white',
        fontSize: '16x',
        fontWeight: '600',
        '&:hover': {
            backgroundColor: 'var(--btn-color)',
        },
        '&:disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
        }
        // Below code will be helpful in some case as it is a quick fix
        // '@media (hover: none)': {
        //     '&:hover': {
        //         backgroundColor: 'var(--btn-color)',
        //     }
        // }
    },
    inputText: {
        // color : 'red',
        // backgroundColor: 'rgba(57, 57, 57, 0.5)',
        borderRadius: 'none',
        '& .MuiOutlinedInput-root.Mui-focused fieldset': {
            // borderColor: 'var(--btn-color)'
        },
        


    },
    framerNumberHeading: {
        textAlign: 'center',
        fontWeight: '700',
        fontSize: '30px',
        marginBottom: '50px'
    },
    MuiInputBaseInput: {
        backgroundColor: 'red!important',
    }
}))