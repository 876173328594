import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    
    fertilizerTypeCardOne: {
        padding: '5px',
        marginBottom: '10px',
        backgroundColor : '#f1ebeb'
    },
    fertilizerTypeCardTwo: {
        padding: '5px',
        marginBottom: '10px',
        // backgroundColor : '#9eaac0',
        backgroundColor: 'rgb(7 55 247 / 20%)',
    },
    fertilizerTypeCardThree: {
        padding: '5px',
        marginBottom: '10px',
        backgroundColor : '#8fcd8d'
    },
    contentWrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        '& p': {
            textAlign : 'center',
        }
    },
    dividerColor: {
        backgroundColor: 'black'
    },
    detailsContainer: {
        textAlign: 'center',
        flex: '50%',
        '& p:first-child': {
            fontWeight: 'bold',
            fontSize: '15px'
        },
        '& span': {
            fontSize: '14px'
        },
        
    },
    greenColor: {
        // color: 'var(--dg-color)',
        color: '#00278f',
        fontWeight: 'bold'
    },
    redColor: {
        color: '#00278f',
        fontWeight: 'bold'
    },
    blueColor: {
        color: '#00278f',
        fontWeight: 'bold'
    },
    overUsed: {
        color: '#f20707',
        fontWeight: 'bold'
    },
    underUsed: {
        color: '#007400',
        fontWeight: 'bold'
    }
}))