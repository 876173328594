import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Container, Box } from "@mui/material";
import ChangeHistoryOutlinedIcon from "@mui/icons-material/ChangeHistoryOutlined";

import {
  AreaDetailsContainer,
  UserDetailsContainer,
  CropTypeDetailsContainer,
  FarmerNumber,
} from "./components";

import UserAPI from "../../services/apis/WheatAPI";
import useStyles from "./WheatReportStyles";
import TransplantDateContainer from "./components/TransplantDate/TransplantDate";
import ManureDetailsContainer from "./components/Manure/ManureDetailsContainer";
import Toast from "cogo-toast";
import {
  getDeviceDetails,
  getIP,
  sendRequestLog,
  language,
  checkNumberInUrl,
} from "../../constants/constants";
import Loader from "../../components/Loader/Loader";

function WheatReport() {
  console.log("INSIDE OLD WHEAT");
  // const toast = useToast();
  const classes = useStyles();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [fetchingUser, setFetchingUser] = useState(false);
  const [usersData, setUsersData] = useState([
    {
      id: 6,
      farmer_name: "सुनयना देवी",
      gaurdian_name: "चंद्रदेव यादव",
      mobile_number: "7070926784",
      land_area: "40.00",
      village_name: "उमन बीघा",
      shg_name: "आदर्श",
      vo_name: "हिमालय",
      block_name: "वजीरगंज",
      district_name: "गया",
      previous_crop_name: "Paddy",
      previous_harvest_date: "01-Nov-2021",
      sowing_date: "20-Nov-2021",
      advised_sowing_date: "01-Nov-2021 to 30-Nov-2021",
      crop_variety: "लोक 01",
      max_grown_crop: "श्री राम सुपर 303",
      urea_per_katta: "2 Kg",
      average_urea: "2 Kg 130 grams",
      advised_urea: {
        advise_value: "3 Kg ",
        difference: "-1 Kg",
      },
      dap_per_katta: "2 Kg",
      average_dap: "2 Kg 130 grams",
      advised_dap: {
        advise_value: "2 Kg 0 grams",
        difference: "0 grams",
      },
      name_of_the_organic_manure:
        "गोबर  खाद ( गनौरा ) (cattle manure - Ganaura)",
      amount_of_cattle_manure_used_in_no_of_tractors: "1.00",
      average_manure: 1.0,
      advised_manure: 0,
      date_of_harvest_of_wheat: "16-Apr-2022",
      total_yield_kg: "2000 Kg",
      yield_per_katta: "50 Kg",
      average_yield: "54 Kg 680 grams",
      quantity_for_self_consumption_kg: "1000 Kg",
      quantity_of_left_over_yield_kg: "1000 Kg",
    },
  ]);
  const [mobileNumberError, setMobileNumberError] = useState(null);
  const [ip, setIp] = useState("");
  const [device, setDevice] = useState("");
  let numberInUrl = checkNumberInUrl();

  let path = window.location.pathname.trim().split("/");
  let languagePath = numberInUrl
    ? path[path.length - 2]
    : path[path.length - 1];
  let label =
    languagePath === "en"
      ? language.en
      : languagePath === "en"
      ? language.hi
      : language.hi;

  useEffect(() => {
    getIP().then((res) => {
      setIp(res);
    });
    setDevice(getDeviceDetails());

    // check if number is number is available in url then show card

    if (numberInUrl) {
      getFarmerNumber(numberInUrl);
    }
  }, []);

  function convertDateFormat(incorrectFormat) {
    // Mapping of month numbers to three-letter month names
    const monthNames = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };

    // Split the input string into year, month, and day
    const [year, month, day] = incorrectFormat.split("-");

    // Check if the month is valid
    if (!monthNames[month]) {
      return null; // Return null if the month is invalid
    }

    // Format the date in DD-MMM-YYYY format
    return `${day}-${monthNames[month]}-${year}`;
  }

  return (
    <>
      {/* <Header handleBackButton={handleBackButton} /> */}
      {fetchingUser ? <Loader /> : null}
      <Container maxWidth="sm">
        {!phoneNumber ? (
          <>
            <FarmerNumber
              sendFarmerNumber={getFarmerNumber}
              fetchingUser={fetchingUser}
              mobileNumberErrorMessage={mobileNumberError}
            />
          </>
        ) : (
          <>
            {usersData.map((data, index) => {
              return (
                <Box className={classes.detailsWrapper} key={index}>
                  {/* pass data in props */}
                  <UserDetailsContainer
                    mobileNumber={data.mobile_number}
                    userName={data.farmer_name}
                    shg={data.shg_name}
                    vo={data.vo_name}
                    village={data.village_name}
                    block={data.block_name}
                    district={data.district_name}
                    label={label}
                  />

                  <AreaDetailsContainer
                    yieldArea={data.land_area}
                    userYield={data.yield_per_katta}
                    averageYield={data.average_yield}
                    label={label}
                  />

                  <CropTypeDetailsContainer
                    cropVariety={data.crop_variety}
                    popularVariety={data.max_grown_crop}
                    label={label}
                  />

                  <TransplantDateContainer
                    transplantDate={
                      path.includes("wheat_2022")
                        ? convertDateFormat(data.sowing_date)
                        : data.sowing_date
                    }
                    advisedTransplantDate={data.advised_sowing_date}
                    label={label}
                  />

                  <ManureDetailsContainer
                    userManureUses={[
                      data.urea_per_katta,
                      data.dap_per_katta,
                      data.amount_of_cattle_manure_used_in_no_of_tractors,
                    ]}
                    averageManureUses={[
                      data.average_urea,
                      data.average_dap,
                      data.average_manure,
                    ]}
                    advisedManureUsage={[
                      data.advised_urea?.advise_value,
                      data.advised_dap?.advise_value,
                      data.advised_manure,
                    ]}
                    userAndAdvisedManureDiff={[
                      data.advised_urea?.difference,
                      data.advised_dap?.difference,
                      data.advised_manure,
                    ]}
                    label={label}
                  />
                </Box>
              );
            })}
          </>
        )}
      </Container>
      {phoneNumber && (
        <AppBar position="static" className={classes.headerContainer}>
          <Toolbar>
            <ChangeHistoryOutlinedIcon
              onClick={handleBackButton}
              className={classes.iconColor}
            />
          </Toolbar>
        </AppBar>
      )}
    </>
  );

  function getFarmerNumber(userPhone) {
    fetchUserDetails(userPhone);
  }

  function handleBackButton() {
    setPhoneNumber("");
  }

  function fetchUserDetails(phone_number) {
    setFetchingUser(true);

    setMobileNumberError(null);

    UserAPI.userDetails(phone_number, path)
      .then((res) => {
        console.log("User Details Fetched: ", res);

        if (res) {
          const userData = res.data;

          if (userData.length === 0) {
            setMobileNumberError(
              "No data found, Please enter valid phone number"
            );
            sendRequestLog(
              "MOB_NOT_FOUND",
              phone_number,
              "wheat_2021",
              ip,
              device
            ); // MOB_NOT_FOUND
            return;
          }
          setUsersData(res.data);
          setPhoneNumber(phone_number);
          setFetchingUser(false);
          sendRequestLog("SUCCESS", phone_number, "wheat_2021", ip, device); // SUCCESS
          return;
        } else {
          console.log("res", res);
          Toast.error(`Unable to load data for ${phone_number}`);
          sendRequestLog("FAILURE", phone_number, "wheat_2021", ip, device); //FAILURE
          return;
        }
      })
      .catch((err) => {
        console.log("User Details Fetching Error: ", err);
        if (err.response?.status === 400) {
          setMobileNumberError(err.response.data);
          sendRequestLog(
            "MOB_NOT_FOUND",
            phone_number,
            "wheat_2021",
            ip,
            device
          ); // MOB_NOT_FOUND
          return;
        }
        if (err.response?.status === 401) {
          setMobileNumberError(err.response.data);
          console.log("err.response.data", err.response.data);
          sendRequestLog(
            "WRONG_VALIDATION",
            phone_number,
            "wheat_2021",
            ip,
            device
          ); // MOB_NOT_FOUND
          return;
        }

        Toast.error(`Unable to load data for ${phone_number}`);
        sendRequestLog("FAILURE", phone_number, "wheat_2021", ip, device); // FAILURE
        return;
      })
      .finally(() => {
        setFetchingUser(false);
      });
  }
}

export default WheatReport;
