import React, { useEffect, useState } from "react";
import { Card, TextField, Box, FormControl, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import useStyles from "./FarmerNumberStyles";

function FramerNumber(props) {
  const [userPhone, setUserPhone] = useState("");
  const [validPhone, setValidPhone] = useState(false);
  const [wrongNumberError, setWrongNumberError] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    setWrongNumberError(props.mobileNumberErrorMessage);
  }, [props.mobileNumberErrorMessage]);

  return (
    <>
      <Box className={classes.framerWrapper}>
        <Box>
          <p className={classes.userHeading}>Paddy Report</p>
          <p className={classes.userHeading}>
            {props.paddy2022 ? "2022" : "2021"}
          </p>
        </Box>
        <Card className={classes.numberContainer}>
          <Typography component="p" className={classes.framerNumberHeading}>
            Log in
          </Typography>
          <Typography variant="subtitle1" component="div">
            Phone Number
          </Typography>
          <FormControl fullWidth size="small">
            <TextField
              sx={{ input: { color: "#FFFFFF" } }}
              type={"number"}
              error={
                userPhone && (!validPhone || props.mobileNumberErrorMessage)
                  ? true
                  : false
              }
              helperText={
                userPhone && !validPhone
                  ? "Please, provide a valid Phone Number"
                  : wrongNumberError
                  ? wrongNumberError
                  : null
              }
              id="farmer-number"
              variant="outlined"
              size="small"
              onChange={handleUserPhoneChange}
              value={userPhone}
              className={classes.inputText}
            />
            {/* {userPhone && !validPhone ? <p>"Please, provide a valid Phone Number"</p>  : (wrongNumberError ? <p>{wrongNumberError}</p> : null)} */}
          </FormControl>
          <LoadingButton
            onClick={handleUserPhone}
            // loading={props.fetchingUser}
            variant="contained"
            fullWidth
            className={classes.submitBtn}
            disabled={validPhone ? null : true}
          >
            Submit
          </LoadingButton>
        </Card>
        <p>
          This is your data, let us know if you see anything inaacurate and
          anytime you need to access or reference it, it will be available for
          you.
        </p>
      </Box>
    </>
  );

  function handleUserPhoneChange(e) {
    setWrongNumberError(null);
    if (e.target.value.length === 10) {
      setValidPhone(true);
    }
    if (e.target.value.length <= 10) {
      setValidPhone(/^[0-9]{10}$/.test(e.target.value));
      setUserPhone(e.target.value);
    }
  }

  function handleUserPhone() {
    props.sendFarmerNumber(userPhone);
  }
}

export default FramerNumber;
