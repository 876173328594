import React from "react";
import { Divider, Card, Typography } from "@mui/material";
import CropSquareIcon from "@mui/icons-material/CropSquare";

import useStyles from "./AreaDetailsStyles";

function AreaDetailsContainer(props) {
  const classes = useStyles();

  return (
    <>
      {
        <Card elevation={3} className={classes.areaCard}>
          {/* <p>
            आपने{" "}
            <strong className={classes.areaQty}>{props.yieldArea} कट्ठा</strong>{" "}
            क्षेत्रफल पे धान की खेती है
          </p> */}
          <div className={classes.icons}>
            <span className={classes.countCircle}>1</span>
            <span>
              <svg
                width="33"
                height="35"
                viewBox="0 0 33 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.0964 31.7211L27.6754 21.5527C27.3069 20.7054 26.4596 20.1158 25.5386 20.1158H23.4017C22.5912 21.6264 21.6701 23.358 20.5648 25.2736C19.6438 26.8577 17.9859 27.8158 16.1438 27.8158C14.3017 27.8158 12.6806 26.8579 11.7228 25.2736C10.6175 23.3578 9.65975 21.6262 8.88594 20.1158H6.74909C5.79127 20.1158 4.98074 20.6684 4.61225 21.5527L0.191265 31.7211C-0.471761 33.2685 0.670291 35.0001 2.36488 35.0001H29.9596C31.6174 35.0001 32.7596 33.2685 32.0965 31.7211H32.0964Z"
                  fill="black"
                />
                <path
                  d="M17.8385 23.6895C20.2702 19.4528 24.507 11.6421 24.507 8.36316C24.507 3.75816 20.749 0 16.1438 0C11.5386 0 7.78064 3.75796 7.78064 8.36316C7.78064 11.6421 12.0173 19.4526 14.4491 23.6895C15.2229 25.0155 17.065 25.0155 17.8387 23.6892L17.8385 23.6895ZM12.2385 8.36316C12.2385 6.22632 13.97 4.45796 16.1437 4.45796C18.3173 4.45796 20.0489 6.18954 20.0489 8.36316C20.0489 10.5 18.3173 12.2684 16.1437 12.2684C13.97 12.2684 12.2385 10.5 12.2385 8.36316Z"
                  fill="black"
                />
              </svg>

              <p>{props.label.area}</p>
            </span>
          </div>
          <div className={classes.yieldDetails}>
            <p>
              {/* आपने{" "}
              <strong className={classes.areaQty}>
                {props.yieldArea} कट्ठा
              </strong>{" "}
              क्षेत्रफल पे धान की खेती है */}
              {props.label.fieldTitle}{" "}
              <strong className={classes.areaQty}>
                {props.yieldArea}
              </strong>
            </p>
          </div>
        </Card>
      }
      {
        <Card elevation={3} className={classes.yieldCard}>
          <div className={classes.icons}>
            <span className={classes.countCircle}>2</span>
            <span>
              <svg
                width="35"
                height="37"
                viewBox="0 0 35 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.65211 23.0961C3.19682 22.7594 2.69073 22.5762 2.06612 22.5762C1.72371 22.5762 1.11541 22.7355 0.50961 22.982C0.506474 23.056 0.498322 23.1287 0.498322 23.2034C0.498322 25.2133 1.68295 26.9422 3.38934 27.7424C3.14602 27.1116 3.0068 26.4286 3.0068 25.7118C3.0068 24.7674 3.24197 23.8781 3.65211 23.0961Z"
                  fill="#050003"
                />
                <path
                  d="M34.6806 29.854C34.8694 29.6822 34.9898 29.4363 34.9898 29.161C34.9898 28.6418 34.5683 28.2203 34.0491 28.2203C33.8133 28.2203 33.6007 28.31 33.4358 28.453C31.6573 29.9305 29.2272 30.7288 26.5237 30.7288C26.0044 30.7288 25.583 31.1502 25.583 31.6695C25.583 32.1887 26.0044 32.6102 26.5237 32.6102C29.9327 32.6102 32.7923 31.4676 34.6806 29.854Z"
                  fill="#050003"
                />
                <path
                  d="M18.0575 2.50848C18.7502 2.50848 19.3117 1.94694 19.3117 1.25424C19.3117 0.561542 18.7502 0 18.0575 0C17.3648 0 16.8033 0.561542 16.8033 1.25424C16.8033 1.94694 17.3648 2.50848 18.0575 2.50848Z"
                  fill="#050003"
                />
                <path
                  d="M2.37896 13.1695C3.07166 13.1695 3.6332 12.6079 3.6332 11.9153C3.6332 11.2226 3.07166 10.661 2.37896 10.661C1.68627 10.661 1.12473 11.2226 1.12473 11.9153C1.12473 12.6079 1.68627 13.1695 2.37896 13.1695Z"
                  fill="#050003"
                />
                <path
                  d="M6.14215 7.52532C6.83485 7.52532 7.39639 6.96378 7.39639 6.27109C7.39639 5.57839 6.83485 5.01685 6.14215 5.01685C5.44945 5.01685 4.88791 5.57839 4.88791 6.27109C4.88791 6.96378 5.44945 7.52532 6.14215 7.52532Z"
                  fill="#050003"
                />
                <path
                  d="M11.7862 3.76275C12.4789 3.76275 13.0404 3.20121 13.0404 2.50851C13.0404 1.81581 12.4789 1.25427 11.7862 1.25427C11.0935 1.25427 10.532 1.81581 10.532 2.50851C10.532 3.20121 11.0935 3.76275 11.7862 3.76275Z"
                  fill="#050003"
                />
                <path
                  d="M33.7355 13.1695C34.4282 13.1695 34.9897 12.6079 34.9897 11.9153C34.9897 11.2226 34.4282 10.661 33.7355 10.661C33.0428 10.661 32.4812 11.2226 32.4812 11.9153C32.4812 12.6079 33.0428 13.1695 33.7355 13.1695Z"
                  fill="#050003"
                />
                <path
                  d="M29.9731 7.52532C30.6658 7.52532 31.2274 6.96378 31.2274 6.27109C31.2274 5.57839 30.6658 5.01685 29.9731 5.01685C29.2804 5.01685 28.7189 5.57839 28.7189 6.27109C28.7189 6.96378 29.2804 7.52532 29.9731 7.52532Z"
                  fill="#050003"
                />
                <path
                  d="M24.3282 3.76275C25.0209 3.76275 25.5825 3.20121 25.5825 2.50851C25.5825 1.81581 25.0209 1.25427 24.3282 1.25427C23.6355 1.25427 23.074 1.81581 23.074 2.50851C23.074 3.20121 23.6355 3.76275 24.3282 3.76275Z"
                  fill="#050003"
                />
                <path
                  d="M34.7444 16.061C32.9872 17.7015 31.7004 18.1687 29.9726 18.1687C28.5422 18.1687 27.6981 17.5585 26.5235 17.5585C23.5798 17.5585 21.193 19.9454 21.193 22.8891C21.193 23.2321 21.2287 23.567 21.2902 23.8925C22.0565 23.5199 22.9307 23.2032 23.7015 23.2032C25.5828 23.2032 26.2099 23.8304 26.2099 23.8304C23.8018 23.8304 21.0569 25.2928 19.3116 26.966C19.3116 18.1863 19.1755 13.5895 18.0574 11.9151C19.8139 13.6717 20.4762 14.3358 20.5571 16.4573C21.7072 15.5386 22.4472 14.1288 22.4472 12.5422C22.4472 7.65821 16.5059 8.12102 16.1911 4.0993C13.9359 5.34414 12.4133 7.67326 12.4133 10.3473C12.4133 13.3813 14.3718 15.97 17.1412 17.0343C17.3807 19.4205 17.4215 22.0086 17.4284 25.0357C15.8625 18.8134 10.9853 15.6778 6.76922 15.6778C2.08965 15.6778 0.647279 18.4403 9.15527e-05 21.8336C0.711872 21.5408 1.49264 21.3219 2.06582 21.3219C6.82817 21.3219 6.76922 28.8473 11.1591 28.8473C11.7109 28.8473 12.2691 28.7376 12.8052 28.5432C12.3017 25.8779 11.1591 23.2032 11.1591 23.2032C15.6003 26.3758 16.8031 30.264 16.8031 35.7456V36.9999H18.6845V35.7456C18.6845 29.4744 20.86 27.5931 23.0743 27.5931C24.8321 27.5931 24.9776 28.2202 26.837 28.2202C31.5129 28.2202 34.9896 24.0085 34.9896 18.8134C34.9896 18.0107 34.8748 16.9797 34.7444 16.061Z"
                  fill="#050003"
                />
              </svg>

              <p>{props.label.yieldTitle}</p>
            </span>
          </div>
          <div className={classes.yieldDetails}>
            <div>
              <Typography
                variant="h6"
                className={
                  props.userYield < props.averageYield
                    ? classes.yieldColorRed
                    : classes.yieldColorGreen
                }
              >
                {props.userYield}
              </Typography>
              <Typography variant="h6" className={classes.yieldTitle}>
                {props.label.farmerYield}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" className={classes.yield}>
                {props.averageYield}
              </Typography>
              <Typography variant="h6" className={classes.yieldTitle}>
                {props.label.averageYield}
              </Typography>
            </div>
          </div>
        </Card>
      }
    </>
  );
}

export default AreaDetailsContainer;
