import React from "react";
import { Card, Divider, Typography } from "@mui/material";

import useStyles from "./TransplantDateStyles";

function TransplantDateContainer(props) {
  const classes = useStyles();

  let months = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
  };
  let path = window.location.pathname.trim().split("/");
  // function convertDateFormat(dateString) {
  //   let date = new Date(dateString);
  //   let day = date.getDate().toString().padStart(2, "0");
  //   let month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-based
  //   let year = date.getFullYear().toString().slice(-2);

  //   return `${day}-${month}-${year}`;
  // }

  // function convertDateFormat(incorrectFormat) {
  //   // Mapping of month numbers to three-letter month names
  //   const monthNames = {
  //     "01": "Jan",
  //     "02": "Feb",
  //     "03": "Mar",
  //     "04": "Apr",
  //     "05": "May",
  //     "06": "Jun",
  //     "07": "Jul",
  //     "08": "Aug",
  //     "09": "Sep",
  //     10: "Oct",
  //     11: "Nov",
  //     12: "Dec",
  //   };

  //   // Split the input string into year, month, and day
  //   const [year, month, day] = incorrectFormat.split("-");

  //   // Check if the month is valid
  //   if (!monthNames[month]) {
  //     return null; // Return null if the month is invalid
  //   }

  //   // Format the date in DD-MMM-YYYY format
  //   return `${day}-${monthNames[month]}-${year}`;
  // }

  // if (path.includes("wheat_2022")) {
  //   console.log(props, "123");
  //   props.transplantDate = convertDateFormat(props.transplantDate);
  // }

  console.log(props.transplantDate, "123");

  let transplantDate = props.transplantDate.trim().split("-");
  console.log("🚀 ~ TransplantDateContainer ~ transplantDate:", transplantDate);

  let transplantDateObj = new Date(
    transplantDate[2],
    months[transplantDate[1]] - 1,
    transplantDate[0]
  );
  console.log(
    "🚀 ~ TransplantDateContainer ~ transplantDateObj:",
    transplantDateObj
  );

  let advisedDate = props.advisedTransplantDate.trim().split(" ");
  let showingStartDate = advisedDate[0].trim().split("-");
  let showingEndDate = advisedDate[2].trim().split("-");

  // console.log('showingStartDate',showingStartDate, 'showingEndDate',showingEndDate);

  let showingStartDateObj = new Date(
    showingStartDate[2],
    months[showingStartDate[1]] - 1,
    showingStartDate[0]
  );

  let showingEndDateObj = new Date(
    showingEndDate[2],
    months[showingEndDate[1]] - 1,
    showingEndDate[0]
  );

  let transplantDateClassName = classes.transplantDateColorRed;
  if (
    transplantDateObj >= showingStartDateObj &&
    transplantDateObj <= showingEndDateObj
  ) {
    transplantDateClassName = classes.transplantDateColorGreen;
  }

  return (
    <>
      {
        <Card elevation={3} className={classes.transplantDateCard}>
          <div className={classes.icons}>
            <span className={classes.countCircle}>4</span>
            <span>
              <svg
                width="38"
                height="37"
                viewBox="0 0 38 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.5261 0L28.523 2.0031C28.2813 2.24481 28.1317 2.57866 28.1317 2.94723C28.1317 3.28175 28.2586 3.58355 28.4623 3.81792L33.1435 9.16821C33.3879 9.4453 33.7418 9.62425 34.1411 9.62425C34.5096 9.62425 34.8435 9.47468 35.0852 9.23297L37.0883 7.22987L30.5261 0Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M22.4604 15.8899C22.2547 16.0736 22.1225 16.338 22.1225 16.6351C22.1225 17.188 22.5706 17.6367 23.1241 17.6367C23.2797 17.6367 23.4252 17.5979 23.5561 17.5352L28.7995 14.9658C30.4541 13.3113 31.65 10.752 32.0172 9.90868L27.796 5.08453L19.4931 8.40568C18.3948 8.84503 17.4693 9.62757 16.8664 10.6452C16.1706 11.8203 15.4435 13.549 15.4455 15.6422C15.4462 16.4915 15.7941 17.3068 16.4043 17.8984L19.1753 20.5839C19.3442 20.7535 19.5612 20.8737 19.8009 20.9324L25.5665 22.2819C25.6406 22.2992 25.7161 22.3106 25.7949 22.3106C26.3484 22.3106 26.7964 21.8619 26.7964 21.309C26.7964 20.8743 26.518 20.5078 26.1301 20.3696L21.336 18.6562C21.0068 18.5387 20.7871 18.2262 20.7871 17.877V15.9701C20.7871 15.7357 20.8866 15.512 21.0609 15.3558L24.9977 11.817C25.1172 11.7008 25.2801 11.6273 25.461 11.6273C25.8296 11.6273 26.1287 11.9265 26.1287 12.295C26.1287 12.5014 26.0299 12.679 25.8824 12.8012L22.4604 15.8899Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M8.76817 11.2935C8.76817 10.7406 9.2162 10.2919 9.76973 10.2919C10.3226 10.2919 10.7713 10.7406 10.7713 11.2935V17.3028C10.7713 17.8557 10.3226 18.3043 9.76973 18.3043C9.2162 18.3043 8.76817 17.8557 8.76817 17.3028V11.2935Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M2.09128 13.9643C2.09128 13.4115 2.53931 12.9628 3.09283 12.9628C3.64569 12.9628 4.09438 13.4115 4.09438 13.9643V20.6413C4.09438 21.1942 3.64569 21.6429 3.09283 21.6429C2.53931 21.6429 2.09128 21.1942 2.09128 20.6413V13.9643Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M12.1068 2.61337C12.1068 2.06051 12.5549 1.61182 13.1084 1.61182C13.6613 1.61182 14.1099 2.06051 14.1099 2.61337V7.95498C14.1099 8.50784 13.6613 8.95653 13.1084 8.95653C12.5549 8.95653 12.1068 8.50784 12.1068 7.95498V2.61337Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M34.8067 34.3292C34.79 31.8988 34.5857 29.6553 32.1379 29.6553C29.4671 29.6553 28.1317 32.3261 24.7932 32.3261C21.4547 32.3261 21.4547 28.9876 16.1131 28.9876C13.901 28.9876 12.1069 30.7817 12.1069 32.9938C12.1069 33.4639 12.197 33.9106 12.3466 34.3292H10.1037C10.3428 32.1565 11.3837 29.8416 12.852 28.378C12.6076 28.3439 12.3606 28.3199 12.1069 28.3199C10.449 28.3199 8.9286 29.0277 7.90434 30.128C7.78282 30.2489 7.61656 30.323 7.43294 30.323C7.06437 30.323 6.76524 30.0239 6.76524 29.6553C6.76524 29.489 6.83201 29.3421 6.93217 29.2253C7.65729 28.4481 8.53265 27.8605 9.50282 27.4793C9.76589 24.3337 10.8369 21.5661 12.3973 19.6618C12.3005 19.6565 12.205 19.6398 12.1069 19.6398C8.89721 19.6398 6.13627 22.4775 4.85695 26.5658C3.52355 25.575 1.877 24.9814 0.0882281 24.9814C0.0581816 24.9814 0.0294704 24.9854 9.15527e-05 24.9861C1.61393 26.3969 2.75903 29.8937 2.75903 33.9953C2.75903 34.1082 2.75302 34.2177 2.75102 34.3292H1.42363V37H36.1441V34.3292H34.8067Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M23.4578 24.3137C23.4578 23.5765 22.8595 22.9783 22.1224 22.9783C20.7796 22.9783 18.1162 24.9941 18.1162 26.3168C18.1162 27.0539 18.7144 27.6522 19.4516 27.6522C20.7943 27.6522 23.4578 25.6364 23.4578 24.3137Z"
                  fill="#1E1E1E"
                />
                <path
                  d="M25.4611 25.6491C25.4611 24.9119 26.0593 24.3137 26.7965 24.3137C28.1392 24.3137 30.8027 26.3295 30.8027 27.6522C30.8027 28.3893 30.2044 28.9876 29.4673 28.9876C28.1245 28.9876 25.4611 26.9718 25.4611 25.6491Z"
                  fill="#1E1E1E"
                />
              </svg>

              <p>{props.label.wheatTransplantDateTitle}</p>
            </span>
          </div>
          <div className={classes.transplantDateDetails}>
            <div>
              <Typography className={transplantDateClassName} variant="h6">
                {props.transplantDate}
              </Typography>
              <Typography variant="h6" className={classes.transplantDateTitle}>
                {props.label.wheatTransplantDate}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" className={classes.transplantDate}>
                {props.advisedTransplantDate}
              </Typography>
              <Typography variant="h6" className={classes.transplantDateTitle}>
                {props.label.wheatAdvisedTransplantDate}
              </Typography>
            </div>
          </div>
        </Card>
      }
    </>
  );
}

export default TransplantDateContainer;
