import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Container, Box, Typography } from "@mui/material";
import ChangeHistoryOutlinedIcon from "@mui/icons-material/ChangeHistoryOutlined";

import {
  UserDetailsContainer,
  AreaDetailsContainer,
  CropTypeDetailsContainer,
  FarmerNumber,
  ManureDetailsContainer,
  TransplantDateContainer,
} from "./components";
import UserAPI from "../../services/apis/WheatAPI";
import useStyles from "./WheatReportStyles";
import Toast from "cogo-toast";
import {
  getDeviceDetails,
  getIP,
  sendRequestLog,
  language,
  checkNumberInUrl,
} from "../../constants/constants";
import Loader from "../../components/Loader/Loader";

function WheatReportNewDesign(props) {
  // const toast = useToast();
  const classes = useStyles();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [fetchingUser, setFetchingUser] = useState(false);
  const [usersData, setUsersData] = useState([
    {
      id: 6, // done (DONE)
      farmer_name: "सुनयना देवी", // q101_fname (DONE) --->
      gaurdian_name: "चंद्रदेव यादव", // q102_FatherName (DONE) --->
      mobile_number: "7070926784", // q104_Mobile (DONE) --->
      land_area: "40.00", // q205_cropLargestArea_y in kattha (DONE) --->
      village_name: "उमन बीघा", //  P_Village_x (DONE) ---> and if I dont have this how can I calculate the   total_urea=Avg('urea_per_katha'),total_dap=Avg('dap_per_katha'), Avg_manure=Avg('amount_of_cattle_manure_used_in_no_of_tractors')
      shg_name: "आदर्श", //SHG (DONE) --->
      vo_name: "हिमालय", //VO (DONE) --->
      block_name: "वजीरगंज", //block (DONE) --->
      district_name: "गया", // district (DONE) --->
      previous_crop_name: "Paddy", //  q301_PreviousCrop (DONE) --->
      previous_harvest_date: "01-Nov-2021", // q315a_pCropHarvestDate (DONE) --->
      sowing_date: "20-Nov-2021", // q408_seedingSowingTransplanting (DONE) NOTE NEED TO REMOVE THE TIME AT THE END --->
      crop_variety: "लोक 01", // q401_VarName (DONE) --->
      advised_sowing_date: "01-Nov-2021 to 30-Nov-2021", // hard coded value
      max_grown_crop: "श्री राम सुपर 303", //  (DONE) --->  need to be calculated in the backend by reading all the crop at the VO level[ varieties]
      urea_per_katta: "2 Kg", //  (DONE) --->   q604e_1tdUrea, q605e_2tdUrea, q606e_3tdUrea on the fly calculation  (DONE)    need to be calculated in the backend and total urea in the sheet in not found but unit is not given and also too many cols
      average_urea: "2 Kg 130 grams", // (DONE)---> based on village   question ? is it the avg of village level urea used?
      advised_urea: {
        advise_value: "3 Kg ", // (DONE) from sheet we can calculate dependind upto the sowing date and no of times the irrigation is done
        difference: "-1 Kg", // on the fly (DONE)
      }, // in the backend hard coded
      dap_per_katta: "2 Kg", // (DONE) ---> q604a_1tdDAP, q605a_2tdDAP, q606a_3tdDAP (DONE) too many cols for dap and which one to take
      average_dap: "2 Kg 130 grams", // (DONE) ---> // based on village
      advised_dap: {
        advise_value: "2 Kg 0 grams", // (DONE) from sheet we can calculate dependind upto the sowing date and no of times the irrigation is done
        difference: "0 grams", // on the fly
      }, // need to discuss
      name_of_the_organic_manure:
        "गोबर  खाद ( गनौरा ) (cattle manure - Ganaura)", // (DONE) ---> q601_OrgFertilizers- name (DONE) --->
      amount_of_cattle_manure_used_in_no_of_tractors: "1.00", // (DONE) ---> q601aa_CropOrgFYM- quantity (DONE) --->
      average_manure: 1.0, // (DONE) ---> avg based on VO (DONE)
      advised_manure: 0, // keep 0 for now (DONE) --->
      date_of_harvest_of_wheat: "16-Apr-2022", //q1302_harv_date (DONE) --->
      total_yield_kg: "2000 Kg", // q1306_yield in kg (DONE) --->
      yield_per_katta: "50 Kg", // (DONE) calculate on the fly  need to calculate
      average_yield: "54 Kg 680 grams", // (DONE) we have query
      quantity_for_self_consumption_kg: "1000 Kg", //  (DONE) hard code 0 --->
      quantity_of_left_over_yield_kg: "1000 Kg", //  (DONE) hard code 0 ---> quantity_for_self_consumption_kg
    },
  ]);

  // q501a_times_irrigation = time irrigation

  const [mobileNumberError, setMobileNumberError] = useState(null);
  const [ip, setIp] = useState("");
  const [device, setDevice] = useState("");

  let numberInUrl = checkNumberInUrl();
  let path = window.location.pathname.trim().split("/");
  let languagePath = numberInUrl
    ? path[path.length - 2]
    : path[path.length - 1];
  let label =
    languagePath === "en"
      ? language.en
      : languagePath === "en"
      ? language.hi
      : language.hi;

  // const checkNumberInUrl = () =>{
  //   let url = window.location.href.split('/');
  //   if(url[url.length-1] !== 'hi' && url[url.length-1] !== 'en'){
  //     let phoneNumber = url[url.length-1]
  //     console.log('phoneNumber', phoneNumber)
  //     getFarmerNumber(phoneNumber)
  //   }
  //   console.log('url',url,url[url.length-1])
  //  }

  useEffect(() => {
    getIP().then((res) => {
      setIp(res);
    });
    setDevice(getDeviceDetails());

    // check if number is number is available in url then show card

    if (numberInUrl) {
      getFarmerNumber(numberInUrl);
    }
  }, []);

  function convertDateFormat(incorrectFormat) {
    // Mapping of month numbers to three-letter month names
    const monthNames = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };

    // Split the input string into year, month, and day
    const [year, month, day] = incorrectFormat.split("-");

    // Check if the month is valid
    if (!monthNames[month]) {
      return null; // Return null if the month is invalid
    }

    // Format the date in DD-MMM-YYYY format
    return `${day}-${monthNames[month]}-${year}`;
  }

  return (
    <>
      {/* <Header handleBackButton={handleBackButton} /> */}
      {fetchingUser ? <Loader /> : null}
      <Container maxWidth="sm" className={classes.container}>
        {!phoneNumber ? (
          <>
            <FarmerNumber
              sendFarmerNumber={getFarmerNumber}
              fetchingUser={fetchingUser}
              mobileNumberErrorMessage={mobileNumberError}
            />
          </>
        ) : (
          <>
            <Box className={classes.userCard}>
              {usersData.map((data, index) => {
                return (
                  <Box className={classes.detailsWrapper} key={index}>
                    <br />
                    <br />
                    <Typography variant="h6" className={classes.cardTitle}>
                      {label.wheatTitle} -{" "}
                      {path.includes("wheat_2022") ? "2022" : "2021"}
                    </Typography>
                    {/* pass data in props */}
                    <UserDetailsContainer
                      mobileNumber={data.mobile_number}
                      userName={data.farmer_name}
                      shg={data.shg_name}
                      vo={data.vo_name}
                      village={data.village_name}
                      block={data.block_name}
                      district={data.district_name}
                      label={label}
                    />

                    <Box className={classes.cropDetails}>
                      <AreaDetailsContainer
                        yieldArea={data.land_area}
                        userYield={data.yield_per_katta}
                        averageYield={data.average_yield}
                        label={label}
                      />

                      <CropTypeDetailsContainer
                        cropVariety={data.crop_variety}
                        popularVariety={data.max_grown_crop}
                        label={label}
                      />

                      <TransplantDateContainer
                        transplantDate={
                          path.includes("wheat_2022")
                            ? convertDateFormat(data.sowing_date)
                            : data.sowing_date
                        }
                        advisedTransplantDate={data.advised_sowing_date}
                        label={label}
                      />

                      <ManureDetailsContainer
                        userManureUses={[
                          data.urea_per_katta,
                          data.dap_per_katta,
                          data.amount_of_cattle_manure_used_in_no_of_tractors,
                        ]}
                        averageManureUses={[
                          data.average_urea,
                          data.average_dap,
                          data.average_manure,
                        ]}
                        advisedManureUsage={[
                          data.advised_urea?.advise_value,
                          data.advised_dap?.advise_value,
                          data.advised_manure,
                        ]}
                        userAndAdvisedManureDiff={[
                          data.advised_urea?.difference,
                          data.advised_dap?.difference,
                          data.advised_manure,
                        ]}
                        label={label}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Container>
      {phoneNumber && (
        <AppBar position="static" className={classes.headerContainer}>
          <Toolbar>
            <ChangeHistoryOutlinedIcon
              onClick={handleBackButton}
              className={classes.iconColor}
            />
          </Toolbar>
        </AppBar>
      )}
    </>
  );

  function getFarmerNumber(userPhone) {
    fetchUserDetails(userPhone);
  }

  function handleBackButton() {
    setPhoneNumber("");
  }

  function fetchUserDetails(phone_number) {
    setFetchingUser(true);

    setMobileNumberError(null);

    UserAPI.userDetails(phone_number, path)
      .then((res) => {
        console.log("User Details Fetched: ", res);

        if (res) {
          const userData = res.data;

          if (userData.length === 0) {
            setMobileNumberError(
              "No data found, Please enter valid phone number"
            );
            sendRequestLog(
              "MOB_NOT_FOUND",
              phone_number,
              "wheat_2021/new_design",
              ip,
              device
            ); // MOB_NOT_FOUND

            return;
          }
          setUsersData(res.data);
          setPhoneNumber(phone_number);
          setFetchingUser(false);
          sendRequestLog(
            "SUCCESS",
            phone_number,
            "wheat_2021/new_design",
            ip,
            device
          ); // SUCCESS
          return;
        } else {
          console.log("res", res);
          Toast.error(`Unable to load data for ${phone_number}`);
          sendRequestLog(
            "FAILURE",
            phone_number,
            "wheat_2021/new_design",
            ip,
            device
          ); //FAILURE
          return;
        }
      })
      .catch((err) => {
        console.log("User Details Fetching Error: ", err);
        if (err.response?.status === 400) {
          setMobileNumberError(err.response.data);
          sendRequestLog(
            "MOB_NOT_FOUND",
            phone_number,
            "wheat_2021/new_design",
            ip,
            device
          ); // MOB_NOT_FOUND
          return;
        }
        if (err.response?.status === 401) {
          setMobileNumberError(err.response.data);
          console.log("err.response.data", err.response.data);
          sendRequestLog(
            "WRONG_VALIDATION",
            phone_number,
            "wheat_2021/new_design",
            ip,
            device
          ); // MOB_NOT_FOUND
          return;
        }

        Toast.error(`Unable to load data for ${phone_number}`);
        sendRequestLog(
          "FAILURE",
          phone_number,
          "wheat_2021/new_design",
          ip,
          device
        ); // FAILURE
        return;
      })
      .finally(() => {
        setFetchingUser(false);
      });
  }
}

export default WheatReportNewDesign;
