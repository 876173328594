import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import useStyles from "./UserDetailsStyles";

function UserDetailsContainer(props) {
  const classes = useStyles();

  return (
    <>
      {
        <Box className={classes.userWrapper}>
          <Typography variant="h3" className={classes.userName}>
            {props.userName}
          </Typography>
          <div className={classes.contentWrapper}>
            {/* <p className={classes.userName}>  {props.userName}</p> */}
            {/* <p className={classes.userPhoneNumber} >
                             {props.mobileNumber}</p> */}
          </div>
          {/* <div className={classes.locationContainer}> */}
          <Box className={classes.locationContainer}>
            <span className={classes.locationLogo}>
              <LocationOnOutlinedIcon className={classes.locationLogo} />
            </span>
            <div>
              <div>{props.label.shg}:</div>
              <div>{props.label.vo}:</div>
              <div>{props.label.village}:</div>
              <div>{props.label.block}:</div>
              <div>{props.label.district}:</div>
            </div>
            <div className={classes.locationValue}>
              <div>
                <b> {props.shg}</b>
              </div>
              <div>
                <b>{props.vo}</b>
              </div>
              <div>
                <b>{props.village}</b>
              </div>
              <div>
                <b>{props.block}</b>
              </div>
              <div>
                <b>{props.district}</b>
              </div>
            </div>
          </Box>
          <Divider />
          <Box className={classes.locationContainer}>
            <span className={classes.locationLogo}>
              <svg
                width="17"
                height="22"
                viewBox="0 0 17 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5862 21.2344L13.6133 21.2092L16.5283 17.8826L12.1213 12.2681L9.07369 13.4913C8.13193 13.1779 7.33775 12.5301 6.84149 11.6706C6.34523 10.811 6.18133 9.79934 6.38083 8.82706L8.96392 6.79938L6.30527 0.175637L2.00309 1.02972L1.96678 1.03688L1.93142 1.04776C1.74364 1.10454 1.57352 1.20843 1.43726 1.34956C1.30099 1.49068 1.20311 1.66433 1.15294 1.85397L0.887958 2.8429C0.377396 4.74834 0.247139 6.73568 0.504623 8.69146C0.762109 10.6472 1.40229 12.5332 2.38862 14.2415C3.37496 15.9499 4.68812 17.4473 6.25313 18.6481C7.81815 19.849 9.60438 20.7299 11.5098 21.2404L12.4988 21.5054C12.6881 21.5568 12.8874 21.5589 13.0778 21.5114C13.2681 21.464 13.4432 21.3686 13.5862 21.2344ZM11.8893 19.8241C4.66957 17.8896 0.369731 10.4421 2.30424 3.22239L2.51866 2.42219L5.39759 1.85086L7.19358 6.3253L5.08212 7.98275L5.01887 8.21878C4.6419 9.632 4.8409 11.137 5.57221 12.4037C6.30353 13.6704 7.50744 14.5953 8.91981 14.9754L9.15584 15.0386L11.6469 14.0388L14.6238 17.831L12.6893 20.0385L11.8893 19.8241Z"
                  fill="#D36643"
                />
              </svg>
            </span>
            <div>{props.mobileNumber}</div>
          </Box>
        </Box>
      }
    </>
  );
}

export default UserDetailsContainer;
