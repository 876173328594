import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    
    fertilizerTypeCardOne: {
        backgroundColor: '#FBEC9D',
        color: '#1E1E1E',
        padding: '5px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // height: '220px',
        boxShadow: 'none',
        borderRadius: '10px',

    },
    fertilizerTypeCardTwo: {
        backgroundColor: '#F7B5CC',
        color: '#1E1E1E',
        padding: '5px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // height: '220px',
        boxShadow: 'none',
        borderRadius: '10px',

    },
    fertilizerTypeCardThree: {
        backgroundColor: '#B6B9ED',
        color: '#1E1E1E',
        padding: '5px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // height: '220px',
        boxShadow: 'none',
        borderRadius: '10px',

    },
    icons: {
        '& span': {
            display: 'inline-block',
        },
        display: 'flex',
        alignItems: 'center',
    },
    contentWrapper: {
        display: 'flex',
        justifyContent: 'space-around',
        '& p': {
            textAlign : 'center',
        }
    },
    dividerColor: {
        backgroundColor: 'black'
    },
    manureDetails: {
        textAlign: 'right',
        marginRight: '24px',
        '& div': {
            margin: '10px 0',
        }
    },
    detailsContainer: {
        textAlign: 'center',
        flex: '50%',
        '& p:first-child': {
            fontWeight: 'bold',
            fontSize: '15px'
        },
        '& span': {
            fontSize: '14px'
        },
        
    },
    greenColor: {
        // color: 'var(--dg-color)',
        fontSize: '18px',
        color: '#00278f',
    },
    redColor: {
        color: '#00278f',
        fontSize: '18px',

    },
    blueColor: {
        fontSize: '18px',
        color: '#00278f',
    },
    overUsed: {
        fontSize: '18px',
        color: '#f20707',
    },
    underUsed: {
        fontSize: '18px',
        color: '#007400',
    },
    manureTitle: {
        fontSize: '14px',
        fontWeight: '300',
    },
    manure: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#1E1E1E',
    },
    countCircle: {
        display: 'inline-block',
            textAlign: 'right',
            width: '48px',
            height: '48px',
            backgroundColor: '#FFFFFF',
            padding: '18px',
            borderRadius: '50%',
            marginRight: '10px',
            marginLeft: '-23px',
    },
}))