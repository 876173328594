import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    headerContainer: {
        backgroundColor: '#fff',
        marginBottom: '5px'
    },
    iconColor: {
        color: 'black'
    }
}))
