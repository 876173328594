import React from 'react';
import { Divider, Card } from '@mui/material';

import useStyles from './AreaDetailsStyles';

function AreaDetailsContainer(props) {

  const classes = useStyles();
  console.log(props, "complete props")
  console.log(props.userYield < props.averageYield)
  function isUserYieldGreater(userYield, averageYield) {
    // Extract the numerical parts of the strings
    const userYieldValue = parseFloat(userYield);
    const averageYieldValue = parseFloat(averageYield);

    // Compare the values and return the result
    return userYieldValue < averageYieldValue;
}
  return (
    <>
        {
           (
            <Card elevation={3} className={classes.areaCard}>
              <p>{props.label.fieldTitle} <strong className={classes.areaQty}>{props.yieldArea} </strong> </p>
            </Card>
          )
        }
        {
           (
            <Card elevation={3} className={classes.yieldCard}>
              <p className='text__center m__b__10 green__text'>{props.label.yieldTitle}</p>
              <div className={classes.contentWrapper}>
                <div className={classes.detailsContainer}>
                  <p>{props.label.farmerYield}</p>
                  <p className={isUserYieldGreater(props.userYield, props.averageYield)  ? classes.yaildColorRed : classes.yaildColorGreen} >{props.userYield}</p>
                </div>
                <Divider orientation="vertical" flexItem className={classes.dividerColor} />
                <div className={classes.detailsContainer}>
                  <p>{props.label.averageYield}</p>
                  <p>{props.averageYield}</p>
                </div>
              </div>
            </Card>
          )
        }
    </>
  )

}

export default AreaDetailsContainer;
