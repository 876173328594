import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    transplantDateCard: {
        backgroundColor: '#BAEBD5',
        color: '#1E1E1E',
        padding: '5px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // height: '148px',
        boxShadow: 'none',
        borderRadius: '10px',
    },
    icons: {
        '& span': {
            display: 'inline-block',
            
        },
        display: 'flex',
        alignItems: 'center',
    },
    dividerColor: {
        backgroundColor: 'black'
    },
    transplantDateDetails: {
        textAlign: 'right',
        marginRight: '24px',
        '& div': {
            margin: '10px 0',
        }
    },
    // detailsContainer: {
    //     textAlign: 'center',
    //     flex: '50%',
    //     '& p:first-child': {
    //         fontWeight: 'bold',
    //         fontSize: '15px',
    //     },
    //     '& span': {
    //         fontSize: '14px'
    //     },
        
    // },
    transplantDateColorGreen: {
        color: 'green',
        fontSize: '18px',
        fontWeight: '500',
    },
    transplantDateColorRed: {
        color: 'red',
        fontSize: '18px',
        fontWeight: '500',
    },
    transplantDateTitle: {
        fontSize: '14px',
        fontWeight: '300',
    },
    transplantDate: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#1E1E1E',
    },
    countCircle: {
        display: 'inline-block',
            textAlign: 'right',
            width: '48px',
            height: '48px',
            backgroundColor: '#FFFFFF',
            padding: '18px',
            borderRadius: '50%',
            marginRight: '10px',
            marginLeft: '-23px',
    },
}))