import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    userWrapper: {
        marginBottom: '10px',
        '& span, svg': {
            fontSize: '14px'
        },
        paddingLeft: '15px'
    },
    userName: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    userPhoneNumber: {
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    contentWrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '5px'
    },
    locationContainer: {
        display: 'block',
        alignItems: 'center'
    },
    
    userHeading: {
        fontSize: '20px',
        textAlign: 'center',
        marginBottom: '5px'
    }
}))