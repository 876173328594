import axios from '../interceptor';
import { headersConfig } from '../../constants/constants';

const UserEndpoints = {
    deviceLog: 'farmer_scorecard_api/access_log/'
}

const DeviceLogAPI = {
    deviceLog: function (data) {
        const url = `/${UserEndpoints.deviceLog}`;
        return axios.post(url, data, headersConfig);
    }
}

export default DeviceLogAPI;