import React from "react";
import { Box } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import useStyles from "./UserDetailsStyles";

function UserDetailsContainer(props) {
  const classes = useStyles();
  let path = window.location.pathname.trim().split("/");

  return (
    <>
      {
        <Box className={classes.userWrapper}>
          <p className={classes.userHeading}> {props.label.wheatTitle} </p>
          <p className={classes.userHeading}>
            {path.includes("wheat_2022") ? "2022" : "2021"}
          </p>
          <div className={classes.contentWrapper}>
            <p className={classes.userName}> {props.userName}</p>
            <p className={classes.userPhoneNumber}>{props.mobileNumber}</p>
          </div>
          <div className={classes.locationContainer}>
            <div>
              {" "}
              <LocationOnOutlinedIcon />{" "}
              <span>
                <b>{props.label.shg} :</b> {props.shg}{" "}
              </span>{" "}
              &nbsp;&nbsp; <b> {props.label.vo} : </b> {props.vo}&nbsp;&nbsp;{" "}
              <span>
                <b> {props.label.village} : </b>{" "}
                {props.village == "nan" ? "N/A" : props.village}
              </span>{" "}
            </div>
            <div>
              {" "}
              <b> {props.label.block} :</b> {props.block} &nbsp;&nbsp;{" "}
              <b> {props.label.district} : </b> {props.district}
            </div>
          </div>
        </Box>
      }
    </>
  );
}

export default UserDetailsContainer;
