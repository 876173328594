import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    transplantDateCard: {
        padding: '5px',
        marginBottom: '10px'
    },
    contentWrapper: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    dividerColor: {
        backgroundColor: 'black'
    },
    detailsContainer: {
        textAlign: 'center',
        flex: '50%',
        '& p:first-child': {
            fontWeight: 'bold',
            fontSize: '15px',
        },
        '& span': {
            fontSize: '14px'
        },
        
    },
    greenColor: {
        color: 'var(--dg-color)',
        fontWeight: 'bold'
    },
    redColor: {
        color: 'red',
        fontWeight: 'bold'
    },
}))