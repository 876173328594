import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Container, Box, Typography } from "@mui/material";
import ChangeHistoryOutlinedIcon from "@mui/icons-material/ChangeHistoryOutlined";

import {
  UserDetailsContainer,
  AreaDetailsContainer,
  CropTypeDetailsContainer,
  FarmerNumber,
  ManureDetailsContainer,
  TransplantDateContainer,
} from "./components";
import UserAPI from "../../services/apis/PaddyAPI";
import useStyles from "./PaddyReportStyles";
import Toast from "cogo-toast";
import {
  getDeviceDetails,
  getIP,
  sendRequestLog,
  language,
  checkNumberInUrl,
} from "../../constants/constants";
import Loader from "../../components/Loader/Loader";

function PaddyReportNewDesign({ paddy2022 }) {
  console.log(
    "🚀 ~ file: PaddyReport.js:26 ~ PaddyReportNewDesign ~ paddy2022:",
    paddy2022
  );
  // const toast = useToast();
  const classes = useStyles();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [fetchingUser, setFetchingUser] = useState(false);
  const [usersData, setUsersData] = useState([
    {
      id: 20,
      mobile_number: "8521663018",
      farmer_name: "तेतरी देवी",
      gaurdian_name: "लेट्स बालचंद्र यादव",
      shg_name: "तुलसी",
      vo_name: "आदर्श",
      village_name: "डोमिया",
      block_name: "गुरुआ",
      district_name: "गया",
      land_area: 5.0,
      yield_perkatta: "75.0 Kg",
      total_yield: "375.0 Kg",
      average_yield: "70.0 Kg",
      crop_name: "0",
      crop_variety: "सीता",
      max_grown_crop: "अमन",
      nursery_sowing_date: "26 July 2022",
      advised_sowing_date: "15-Jun-2022 to 30-Jul-2022",
      qty_cattelmanure_fertilizer: 1.0,
      average_manure: 22.0,
      advised_manure: 0,
      urea_per_katta: "2.0 Kg ",
      average_urea: "1.0 Kg 340 grams",
      advised_urea: {
        advise_value: "1 Kg 750 grams",
        difference: "250 grams",
      },
      dap_per_katta: "1.0 Kg 399 grams",
      average_dap: "1.0 Kg 330 grams",
      advised_dap: {
        advise_value: "1 Kg 120 grams",
        difference: "280 grams",
      },
    },
  ]);

  const [mobileNumberError, setMobileNumberError] = useState(null);
  const [ip, setIp] = useState("");
  const [device, setDevice] = useState("");

  let numberInUrl = checkNumberInUrl();
  let path = window.location.pathname.trim().split("/");

  let languagePath = numberInUrl
    ? path[path.length - 2]
    : path[path.length - 1];
  let label =
    languagePath === "en"
      ? language.en
      : languagePath === "en"
      ? language.hi
      : language.hi;

  console.log("label", label);

  useEffect(() => {
    getIP().then((res) => {
      setIp(res);
    });
    setDevice(getDeviceDetails());

    // check if number is number is available in url then show card

    if (numberInUrl) {
      getFarmerNumber(numberInUrl);
    }
  }, []);

  return (
    <>
      {/* <Header handleBackButton={handleBackButton} /> */}
      {fetchingUser ? <Loader /> : null}
      <Container maxWidth="sm" className={classes.container}>
        {!phoneNumber ? (
          <>
            <FarmerNumber
              paddy2022={paddy2022}
              sendFarmerNumber={getFarmerNumber}
              fetchingUser={fetchingUser}
              mobileNumberErrorMessage={mobileNumberError}
            />
          </>
        ) : (
          <>
            <Box className={classes.userCard}>
              {usersData.map((data, index) => {
                return (
                  <Box className={classes.detailsWrapper} key={index}>
                    <br />
                    <br />
                    <Typography variant="h6" className={classes.cardTitle}>
                      {label.title} - {paddy2022 ? "2022" : "2021"}
                    </Typography>
                    {/* pass data in props */}
                    <UserDetailsContainer
                      mobileNumber={data.mobile_number}
                      userName={data.farmer_name}
                      shg={data.shg_name}
                      vo={data.vo_name}
                      village={data.village_name}
                      block={data.block_name}
                      district={data.district_name}
                      label={label}
                    />

                    <Box className={classes.cropDetails}>
                      <AreaDetailsContainer
                        yieldArea={data.land_area}
                        userYield={data.yield_perkatta}
                        averageYield={data.average_yield}
                        label={label}
                      />

                      <CropTypeDetailsContainer
                        cropVariety={data.crop_variety}
                        popularVariety={data.max_grown_crop}
                        label={label}
                      />

                      <TransplantDateContainer
                        transplantDate={data.nursery_sowing_date}
                        advisedTransplantDate={data.advised_sowing_date}
                        label={label}
                      />

                      <ManureDetailsContainer
                        userManureUses={[
                          data.urea_per_katta,
                          data.dap_per_katta,
                          data.qty_cattelmanure_fertilizer,
                        ]}
                        averageManureUses={[
                          data.average_urea,
                          data.average_dap,
                          data.average_manure,
                        ]}
                        advisedManureUsage={[
                          data.advised_urea?.advise_value,
                          data.advised_dap?.advise_value,
                          data.advised_manure,
                        ]}
                        userAndAdvisedManureDiff={[
                          data.advised_urea?.difference,
                          data.advised_dap?.difference,
                          data.advised_manure,
                        ]}
                        label={label}
                        paddy2022={paddy2022}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Container>
      {phoneNumber && (
        <AppBar position="static" className={classes.headerContainer}>
          <Toolbar>
            <ChangeHistoryOutlinedIcon
              onClick={handleBackButton}
              className={classes.iconColor}
            />
          </Toolbar>
        </AppBar>
      )}
    </>
  );

  function getFarmerNumber(userPhone) {
    fetchUserDetails(userPhone);
  }

  function handleBackButton() {
    setPhoneNumber("");
  }

  function fetchUserDetails(phone_number) {
    setFetchingUser(true);

    setMobileNumberError(null);
    let route = "";
    if (paddy2022) {
      route = "paddy_2022";
    }
    console.log(
      "🚀 ~ file: PaddyReport.js:184 ~ fetchUserDetails ~ route:",
      route
    );

    UserAPI.userDetails(phone_number, route)
      .then((res) => {
        console.log("User Details Fetched: ", res);

        if (res) {
          const userData = res.data;

          if (userData.length === 0) {
            setMobileNumberError(
              "No data found, Please enter valid phone number"
            );
            sendRequestLog(
              "MOB_NOT_FOUND",
              phone_number,
              "paddy_2021/new_design",
              ip,
              device
            ); // MOB_NOT_FOUND

            return;
          }
          setUsersData(res.data);
          setPhoneNumber(phone_number);
          setFetchingUser(false);
          sendRequestLog(
            "SUCCESS",
            phone_number,
            "paddy_2021/new_design",
            ip,
            device
          ); // SUCCESS
          return;
        } else {
          console.log("res", res);
          Toast.error(`Unable to load data for ${phone_number}`);
          sendRequestLog(
            "FAILURE",
            phone_number,
            "paddy_2021/new_design",
            ip,
            device
          ); //FAILURE
          return;
        }
      })
      .catch((err) => {
        console.log("User Details Fetching Error: ", err);
        if (err.response?.status === 400) {
          setMobileNumberError(err.response.data);
          sendRequestLog(
            "MOB_NOT_FOUND",
            phone_number,
            "paddy_2021/new_design",
            ip,
            device
          ); // MOB_NOT_FOUND
          return;
        }
        if (err.response?.status === 401) {
          setMobileNumberError(err.response.data);
          console.log("err.response.data", err.response.data);
          sendRequestLog(
            "WRONG_VALIDATION",
            phone_number,
            "paddy_2021/new_design",
            ip,
            device
          ); // MOB_NOT_FOUND
          return;
        }

        Toast.error(`Unable to load data for ${phone_number}`);
        sendRequestLog(
          "FAILURE",
          phone_number,
          "paddy_2021/new_design",
          ip,
          device
        ); // FAILURE
        return;
      })
      .finally(() => {
        setFetchingUser(false);
      });
  }
}

export default PaddyReportNewDesign;
