import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  yieldCard: {
    backgroundColor: "#B6B9ED",
    color: "#1E1E1E",
    padding: "5px",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "148px",
    boxShadow: "none",
    borderRadius: "10px",
  },
  areaCard: {
    backgroundColor: "#ade08e82",
    color: "#1E1E1E",
    padding: "5px",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "148px",
    boxShadow: "none",
    borderRadius: "10px",
  },
  icons: {
    "& span": {
      display: "inline-block",
    },
    display: "flex",
    alignItems: "center",
  },
  yieldDetails: {
    textAlign: "right",
    marginRight: "24px",
    "& div": {
      margin: "10px 0",
    },
    width: "60%",
  },

  detailsContainer: {
    textAlign: "center",
    flex: "50%",

    "& p:last-child": {
      fontWeight: "bold",
      fontSize: "15px",
    },
  },
  dividerColor: {
    backgroundColor: "black",
  },
  yieldColorGreen: {
    color: "green",
    fontSize: "18px",
    fontWeight: "500",
  },
  yieldColorRed: {
    color: "red",
    fontSize: "18px",
    fontWeight: "500",
  },
  yieldTitle: {
    fontSize: "14px",
    fontWeight: "300",
  },
  yield: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#1E1E1E",
  },
  countCircle: {
    display: "inline-block",
    textAlign: "right",
    width: "48px",
    height: "48px",
    backgroundColor: "#FFFFFF",
    padding: "18px",
    borderRadius: "50%",
    marginRight: "10px",
    marginLeft: "-23px",
  },
  // yieldCardDiv:{
  //     display: 'flex',
  //     alignItems: 'center',

  // },
}));
