import axios from 'axios';
import { baseUrl } from '../constants/constants';

const BASE_URL = baseUrl;

const interceptedAxios = axios.create({
    baseURL: BASE_URL
});

// Implemented Request Handler 
const requestHandler = req => {
    
    return req;
}

const errorHandler = err => {
    return Promise.reject(err);
}

interceptedAxios.interceptors.request.use(
    req => requestHandler(req),
    err => errorHandler(err)
)

export default interceptedAxios;