import axios from "../interceptor";
import { headersConfig } from "../../constants/constants";

const UserEndpoints = {
  userDetails: "farmer_scorecard_api/wheat_2021/",
  userDetailsYear2022: "farmer_scorecard_api/paddy_2022/",

  // wheat endpoint for the 2022
  wheat2022: "farmer_scorecard_api/wheat_2022/",
};

const UserAPI = {
  userDetails: function (data, route) {
    console.log(route, "route123");

    // let baseurl = "http://127.0.0.1:8000"
    let baseurl = "https://frame.digitalgreen.org/be";
    let url = `/${UserEndpoints.userDetails}${data}/`;
    if (route == "paddy_2022") {
      url = `/${UserEndpoints.userdetailsYear2022}${data}/`;
    } else if (route == "wheat_2022") {
      url = `/${UserEndpoints.wheat2022}${data}/`;
    }

    if (route.includes("wheat_2022")) {
      url = `/${UserEndpoints.wheat2022}${data}/`;
    }

    return axios.get(baseurl + url, headersConfig);
  },
};

export default UserAPI;
