import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    fertilizerUsageCard: {
        backgroundColor: 'var(--ly-color)',
        padding: '5px',
        fontWeight: 'bold'
    },
    fertilizerQtyCard: {
        backgroundColor: 'var(--lg-color)',
        padding: '5px',
        marginBottom: '10px'
    },
    contentWrapper: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    detailsContainer: {
        textAlign: 'center',
        flex: '50%'
    },
    fertilizerQty: {
        color: 'var(--dg-color)',
        fontWeight: 'bold'
    }
}))