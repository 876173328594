import React from 'react';
import { Card, Divider } from '@mui/material';

import useStyles from './TransplantDateStyles'

function TransplantDateContainer(props) {



  const classes = useStyles();

  let months = {
    'Jan' : 1,
    'Feb' : 2,
    'Mar' : 3,
    'Apr' : 4,
    'May' : 5,
    'Jun' : 6,
    'Jul' : 7,
    'Aug' : 8,
    'Sep' : 9,
    'Oct' : 10,
    'Nov' : 11,
    'Dec' : 12
  };

  let transplantDate = props.transplantDate.trim().split('-');
  let transplantDateObj = new Date(transplantDate[2], months[transplantDate[1]] - 1, transplantDate[0]);

  let advisedDate = props.advisedTransplantDate.trim().split(' ');
  let showingStartDate = advisedDate[0].trim().split('-');
  let showingEndDate = advisedDate[2].trim().split('-');

  

  let showingStartDateObj = new Date(showingStartDate[2], months[showingStartDate[1]] - 1, showingStartDate[0]);

  let showingEndDateObj = new Date(showingEndDate[2], months[showingEndDate[1]] - 1, showingEndDate[0]);

  let transplantDateClassName = classes.redColor;
  if(transplantDateObj >= showingStartDateObj && transplantDateObj <= showingEndDateObj){
    transplantDateClassName = classes.greenColor;
  }




  return (
      <>
        {(
            <Card elevation={3} className={classes.transplantDateCard}>
              <p className='text__center m__b__10 green__text'>{props.label.wheatTransplantDateTitle}</p>
              <div className={classes.contentWrapper}>
              <div className={classes.detailsContainer}>
                <p>{props.label.transplantDate}</p>
                  <p className={transplantDateClassName}>{props.transplantDate}</p>
              </div>
                <Divider flexItem orientation='vertical' className={classes.dividerColor} />
                <div className={classes.detailsContainer}>
                <p>{props.label.advisedTransplantDate}</p>
                <p >{props.advisedTransplantDate}</p>
                </div>
              </div>
            </Card>
          )
        }
      </>
  )
  
  

    }

export default TransplantDateContainer;
