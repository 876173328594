import React from 'react';
import { AppBar, Toolbar } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import useStyles from './HeaderStyles'

    function Header({handleBackButton}) {

    const classes = useStyles();
    handleBackButton = () => {
        window.location.reload();
    }

    return (
        <>
            <AppBar position="static" className={classes.headerContainer}>
                <Toolbar>
                    <HomeOutlinedIcon onClick={handleBackButton} className={classes.iconColor} />
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Header;