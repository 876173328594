import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
    cropTypeCard: {
        backgroundColor: '#95E1F7',
        color: '#1E1E1E',
        padding: '5px',
        marginBottom: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // height: '148px',
        boxShadow: 'none',
        borderRadius: '10px',

    },
    icons: {
        '& span': {
            display: 'inline-block',
        },
        display: 'flex',
        alignItems: 'center',
    },
    cropTypeDetails: {
        textAlign: 'right',
        marginRight: '24px',
        '& div': {
            margin: '10px 0',
        }
    },
    dividerColor: {
        backgroundColor: 'black'
    },
    detailsContainer: {
        textAlign: 'center',
        flex: '50%',
        '& p:first-child': {
            fontWeight: 'bold',
            fontSize: '15px'
        },
        '& span': {
            fontSize: '14px'
        },
        
    },
    greenColor: {
        color: 'var(--dg-color)',
        fontWeight: 'bold'
    },
    cropTypeTitle: {
        fontSize: '14px',
        fontWeight: '300',
    },
    cropType: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#1E1E1E',
    },
    countCircle: {
        display: 'inline-block',
            textAlign: 'right',
            width: '48px',
            height: '48px',
            backgroundColor: '#FFFFFF',
            padding: '18px',
            borderRadius: '50%',
            marginRight: '10px',
            marginLeft: '-23px',
    },
}))