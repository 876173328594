import styled from "styled-components";
import { Box } from "@mui/system";
import React from 'react'

import LinearProgress from '@mui/material/LinearProgress'

const DisabledBackground = styled(Box)({
    width: '500%',
    height: '500%',
    position: 'fixed',
    background: '#777',
    opacity: 1,
    zIndex: 1900,
    margin: '-100%'
  })

const Loader = (props) => (
    <>
      <div
        style={{
          position: 'fixed',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 2000,
          textAlign: 'center'
        }}
      >
        <LinearProgress color="primary" /> <br />
        <span className="titleText" style={{'color' : 'white'}}>
          Please wait while the request is being processed
        </span>
      </div>
      <DisabledBackground />
    </>
  )

  export default Loader;