import React from 'react';
import { Card, Divider } from '@mui/material';

import useStyles from './CropTypeDetailsStyles'

function CropTypeContainer(props) {


  const classes = useStyles();



  return (
      <>
        {
         (
            <Card elevation={3} className={classes.cropTypeCard}>
              <p className='text__center m__b__10 green__text'>{props.label.wheatCropTypeTitle}</p>
              <div className={classes.contentWrapper}> 
              <div className={classes.detailsContainer}>
                 <p>{props.label.wheatCropType}</p>
                  <p className={classes.greenColor}>{props.cropVariety}</p>
                </div>
              
                <Divider flexItem orientation='vertical' className={classes.dividerColor} />
              
                <div className={classes.detailsContainer}>
                <p>{props.label.wheatFamousCropType}</p>
                  <p>{props.popularVariety}</p>
                </div>
                </div>
              
            </Card>
          )
        }
      </>
  )


}

export default CropTypeContainer;
