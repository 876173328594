import { makeStyles } from '@mui/styles'
import { border } from '@mui/system'

export default makeStyles(() => ({
    userWrapper: {
        margin: '16px',
        '& span, svg': {
            fontSize: '15px'
        },
        padding: '15px 15px 1px 35px',
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
    },
    userName: {
        fontSize: '30px',
        fontWeight: '600',
    },
    userPhoneNumber: {
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    contentWrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        fontWeight: 'bold',
        fontSize: '20px',
        marginBottom: '5px'
    },
    locationContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        margin: '10px 0px',
        '& div': {
            fontSize: '15px',
            lineHeight: '18px',
            color: '#656767',
        },

    },
    
    userHeading: {
        fontSize: '20px',
        textAlign: 'center',
        marginBottom: '5px'
    },
    locationLogo: {
        alignSelf: 'start',
        color: '#D36643',
        height: '25px',
        width: '25px',
    },
    locationValue: {
        marginLeft: '40px',
    },
}))