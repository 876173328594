import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
    framerWrapper: {
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '80vh'
    },
    userHeading: {
        fontSize: '20px',
        textAlign: 'center',
        marginBottom: '5px'
    },
    numberContainer: {
        padding: '15px',
        marginBottom: '10px',
        marginTop: '30px',
        borderRadius: '10px'
    },
    submitBtn: {
        marginTop: '20px',
        borderRadius: '20px',
        backgroundColor: 'var(--btn-color)',
        color: 'black',
        '&:hover': {
            backgroundColor: 'var(--btn-color)',
        },
        '&:disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
        }
        // Below code will be helpful in some case as it is a quick fix
        // '@media (hover: none)': {
        //     '&:hover': {
        //         backgroundColor: 'var(--btn-color)',
        //     }
        // }
    },
    inputText: {
        '& .MuiOutlinedInput-root.Mui-focused fieldset': {
            borderColor: 'var(--btn-color)'
        }
    }
}))