import axios from "axios";
import DeviceLogAPI from "../services/apis/DeviceLogAPI";

// export const baseUrl = "http://44.238.12.251:9002/"
export const baseUrl = "https://frame.digitalgreen.org/be";

export const getDeviceDetails = () => {
  var module = {
    options: [],
    header: [
      navigator.platform,
      navigator.userAgent,
      navigator.appVersion,
      navigator.vendor,
      window.opera,
    ],
    dataos: [
      { name: "Windows Phone", value: "Windows Phone", version: "OS" },
      { name: "Windows", value: "Win", version: "NT" },
      { name: "iPhone", value: "iPhone", version: "OS" },
      { name: "iPad", value: "iPad", version: "OS" },
      { name: "Kindle", value: "Silk", version: "Silk" },
      { name: "Android", value: "Android", version: "Android" },
      { name: "PlayBook", value: "PlayBook", version: "OS" },
      { name: "BlackBerry", value: "BlackBerry", version: "/" },
      { name: "Macintosh", value: "Mac", version: "OS X" },
      { name: "Linux", value: "Linux", version: "rv" },
      { name: "Palm", value: "Palm", version: "PalmOS" },
    ],
    databrowser: [
      { name: "Chrome", value: "Chrome", version: "Chrome" },
      { name: "Firefox", value: "Firefox", version: "Firefox" },
      { name: "Safari", value: "Safari", version: "Version" },
      { name: "Internet Explorer", value: "MSIE", version: "MSIE" },
      { name: "Opera", value: "Opera", version: "Opera" },
      { name: "BlackBerry", value: "CLDC", version: "CLDC" },
      { name: "Mozilla", value: "Mozilla", version: "Mozilla" },
    ],
    init: function () {
      var agent = this.header.join(" "),
        os = this.matchItem(agent, this.dataos),
        browser = this.matchItem(agent, this.databrowser);

      return { os: os, browser: browser };
    },
    matchItem: function (string, data) {
      var i = 0,
        j = 0,
        html = "",
        regex,
        regexv,
        match,
        matches,
        version;

      for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, "i");
        match = regex.test(string);
        if (match) {
          regexv = new RegExp(data[i].version + "[- /:;]([\\d._]+)", "i");
          matches = string.match(regexv);
          version = "";
          if (matches) {
            if (matches[1]) {
              matches = matches[1];
            }
          }
          if (matches) {
            matches = matches.split(/[._]+/);
            for (j = 0; j < matches.length; j += 1) {
              if (j === 0) {
                version += matches[j] + ".";
              } else {
                version += matches[j];
              }
            }
          } else {
            version = "0";
          }
          return {
            name: data[i].name,
            version: parseFloat(version),
          };
        }
      }
      return { name: "unknown", version: 0 };
    },
  };
  var e = module.init(),
    debug = "";

  debug += "os.name = " + e.os.name + ";";
  debug += "os.version = " + e.os.version + ";";
  debug += "browser.name = " + e.browser.name + ";";
  debug += "browser.version = " + e.browser.version + ";";

  debug += "   ";
  debug += "navigator.userAgent = " + navigator.userAgent + ";";
  debug += "navigator.appVersion = " + navigator.appVersion + ";";
  debug += "navigator.platform = " + navigator.platform + ";";
  debug += "navigator.vendor = " + navigator.vendor + ";";

  return debug;
};

export const getIP = async () => {
  const res = await axios.get("https://geolocation-db.com/json/");

  return res.data.IPv4;
};

export const sendRequestLog = (status, phone_number, route, ip, device) => {
  const data = {
    ip_address: ip,
    device_info: device,
    access_type: route,
    mobile_number: phone_number,
    access_time: new Date(),
    call_status: status,
  };

  DeviceLogAPI.deviceLog(JSON.stringify(data))
    .then((res) => {
      console.log("res", res);
    })
    .catch((err) => {
      console.log("err", err);
    });
};
// const headers
export const headersConfig = {
  headers: {
    username: "digitalgreen",
    password: "4oW@r60xp10JJW@",
  },
};

// Create json for multi luanguage "hi" and "en"
export const language = {
  hi: {
    title: "आपकी धान रिपोर्ट",
    wheatTitle: "आपकी गेहूँ रिपोर्ट",
    shg: "एस.हेच.जी",
    vo: "वि.ओ ",
    village: "गाँव",
    block: "ब्लॉक",
    district: "जिला",
    fieldTitle: "आपकी खेती है (कट्ठा)",
    area: "क्षेत्रफल",
    yieldTitle: "उपज (प्रति कट्ठा)",
    yield: "उपज",
    farmerYield: "आपकी उपज",
    averageYield: "औसत उपज (आपके धान का)",
    wheatAverageYield: "औसत उपज (आपके गेहूं का)",
    cropTypeTitle: "धान के प्रकार",
    wheatCropTypeTitle: "गेहूँ के प्रकार",
    cropType: "धान के प्रकार",
    wheatCropType: "आपका गेहूँ",
    famousCropType: "लोकप्रिय धान",
    wheatFamousCropType: "लोकप्रिय गेहूँ",
    transplantDateTitle: "रोपाई तिथि",
    wheatTransplantDateTitle: "बुआई तिथि",
    transplantDate: "आपकी रोपाई तिथि",
    wheatTransplantDate: "आपकी बुआई तिथि",
    advisedTransplantDate: "सलाह रोपाई तिथि",
    wheatAdvisedTransplantDate: "सलाह बुआई तिथि",
    manureTitleSentence: "प्रति कट्ठा रासायनिक खाद का प्रयोग",
    manure1: "यूरिया (प्रति कट्ठा)",
    manure2: "डी.ए.पी (प्रति कट्ठा)",
    manure3: "जैविक खाद का प्रयोग",
    farmerUsesManure: "आपने इस्तमाल किया",
    averageUsesManure: "औसत इस्तमाल (आपके गांव का)",
    advisedManure: "सलाह दी उर्वरक",
    tractor: "ट्रैक्टर",
    quintal: "क्विंटल",
    averageUsesManureVo: "औसत इस्तमाल (वि.ओ)",
  },
  en: {
    title: "Your Paddy Report",
    wheatTitle: "Your Wheat Report",
    shg: "S.H.G",
    vo: "V.O",
    village: "Village",
    block: "Block",
    district: "District",
    fieldTitle: "Land Area (katta)",
    area: "Area",
    yieldTitle: "Yield per Katta",
    yield: "Yield",
    farmerYield: "Your Yield",
    averageYield: "Average Yield (Your crop variety)",
    wheatAverageYield: "Average Yield (Your crop variety)",
    cropTypeTitle: "Crop Variety",
    wheatCropTypeTitle: "Crop Variety",
    cropType: "Your Crop Variety",
    wheatCropType: "Your Crop Variety",
    famousCropType: "Famous Crop Variety (In your village)",
    wheatFamousCropType: "Famous Crop Variety (In your village)",
    transplantDateTitle: "Transplantation Date",
    wheatTransplantDateTitle: "Transplantation Date",
    transplantDate: "Your Transplantation Date",
    wheatTransplantDate: "Your Transplantation Date",
    advisedTransplantDate: "Advised Transplantation Date",
    wheatAdvisedTransplantDate: "Advised Transplantation Date",
    manureTitleSentence: "Use of Chemical Fertilizer per Kattha",
    manure1: "Urea (per katta)",
    manure2: "D.A.P (per katta)",
    manure3: "Use of Organic Fertilizer",
    farmerUsesManure: "Your Usage",
    averageUsesManure: "Average Usage (Farmer's Village)",
    advisedManure: "Advised Fertilizer Usage",
    tractor: "Tractor",
    quintal: "Quintal",
    averageUsesManureVo: "Average Usage (V.O)",
  },
};

export const checkNumberInUrl = () => {
  console.log("in checkNumberInUrl");
  let url = window.location.href.split("/");
  console.log("🚀 ~ file: constants.js:217 ~ checkNumberInUrl ~ url:", url);
  if (url[url.length - 1] !== "hi" && url[url.length - 1] !== "en") {
    let phoneNumber =
      url[url.length - 1] == "" ? url[url.length - 2] : url[url.length - 1];
    console.log("phoneNumber", phoneNumber);
    return phoneNumber;
  }
  console.log("url", url, url[url.length - 1]);
  return null;
};
